import React, { useState } from 'react';
import PropTypes from 'prop-types';

import img from '../../assets/images/layouts/portfolio1.jpeg'

Portfolio.propTypes = {
    data: PropTypes.array
};

function Portfolio(props) {

    const { data, refProp } = props;

    const [dataBlock] = useState(
        {
            subheading: 'Join the adventure',
            heading: 'Buy BOTY token',

        }
    )
    return (
        <section ref={refProp} style={{ marginBottom: '100px', marginTop: '200px' }} className="portfolio">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>

                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                        <div className="portfolio__left">
                            {
                                data.map(idx => (
                                    <div key={idx.id} className="portfolio-box">
                                        <div className="step">Step {idx.id}</div>
                                        <div className="icon">
                                            <img src={idx.img} alt="Cyfonii" />

                                        </div>
                                        <div className="content">
                                            <h5 className="title">{idx.title}</h5>
                                            <p>{idx.desc}</p>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>



                    <div className="col-xl-6 col-md-6">
                        <div className="portfolio__right">
                            <iframe
                                src="https://app.uniswap.org/#/swap"
                                height="760px"
                                width="100%"
                                style={{
                                    paddingTop: '140px',
                                    border: '0',
                                    margin: '0 auto',
                                    display: 'block',
                                    borderRadius: '10px',
                                    maxWidth: '500px',
                                    minWidth: '300px',
                                    maxHeight: '800px',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Portfolio;
