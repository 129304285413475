import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Link } from 'react-router-dom';

Project.propTypes = {
    data: PropTypes.array
};

function Project(props) {
    const { data } = props;

    const [dataBlock] = useState(
        {
            subheading: 'Our Speciality',
            heading: 'OUR SPACE ARMY',

        }
    )
    return (
        <section className="project">
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading" >{dataBlock.heading}</h3>
                        </div>

                        <Swiper
                            className="project-swiper"
                            spaceBetween={30}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                991: {
                                    slidesPerView: 3,
                                },
                            }}
                            loop={true}
                            modules={[Navigation, Pagination]}
                            navigation
                            pagination={{
                                clickable: true,
                            }}

                        >

                            {
                                data.map(idx => (
                                    <SwiperSlide key={idx.id}>
                                        <div className="swiper-slide">
                                            <div className="project-box">
                                                <div className="image" style={{ display: "flex", justifyContent: "center" }}>
                                                    <img src={idx.img} alt="Cyfonii" />
                                                </div>
                                                <div className="content">
                                                    <div className="h5 title">{idx.title}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }

                        </Swiper>

                    </div>

                </div>
            </div>
            <div id="roadmap" ></div>
        </section>
    );
}

export default Project;
