import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';



const Popup = (props) => {
    //const [show, setShow] = useState(false);
    // useEffect(() => {
    // verify if user already accepted terms in local storage
    //   const termsAccepted = localStorage.getItem('termsAcceptedd') === 'true';
    //  if (!termsAccepted) {
    //     setShow(true);
    // }
    // }, []);
    //  const acceptTerms = () => {
    //     setShow(false);
    //localStorage.setItem('termsAccepted', 'false');
    //  };
    //  if (show) {
    return (
        <div style={{ padding: '5rem' }} id="popup">
            <div className="popup__content">
                <h2 style={{ textAlign: "center" }}>Disclaimer</h2>
                <br />
                <p>The following disclaimer is intended to provide a clear understanding of the risks associated with engaging in the BOTY Token and its associated project. It is crucial to exercise caution and undertake extensive research before participating in any blockchain, cryptocurrency, or token-related activities. The BOTY Token and its project are solely intended for educational, entertainment, and experimental purposes. The information provided in our content, communications, and any other materials should not be interpreted as financial, investment, legal, or professional advice of any kind.</p>
                <br />
                <p>By choosing to engage with the BOTY Token and project, you acknowledge and fully understand that you are voluntarily participating in a high-risk experiment. You assume sole responsibility for any decisions you make, including the potential risks, losses, and consequences that may arise. The volatility, uncertainty, and regulatory nature of the blockchain and cryptocurrency space make it inherently risky, and the BOTY Token is no exception.</p>
                <br />
                <p>We strongly advise all individuals to conduct their own comprehensive research and analysis, taking into account their personal financial situation, objectives, and risk tolerance. It is recommended to seek advice from qualified professionals, such as financial advisors, legal experts, or tax consultants, before engaging in any token-related activities.</p>
                <br />
                <p>The information and materials provided regarding the BOTY Token and project are subject to change without notice. We make no warranties or representations, expressed or implied, regarding the accuracy, completeness, or reliability of the information presented. Furthermore, we disclaim any responsibility for the actions, decisions, or outcomes resulting from the use of the information provided.</p>
                <br />
                <p>In summary, engaging with the BOTY Token and its associated project involves inherent risks. Proceed with caution, conduct thorough research, and seek professional advice to make well-informed decisions. By choosing to participate, you agree to release us from any liability or responsibility for any consequences that may arise from your involvement with the BOTY Token and project.</p>
            </div>
        </div>
    );
    // }
};

export default Popup;
