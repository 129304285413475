import React, { useEffect, useState } from 'react';
import img5 from '../assets/images/layouts/about-05.png'

import PageTitle from '../components/pagetitle/PageTitle';
import FaqPage from '../components/faqs/FaqPage';
import dataFaqs from '../assets/fake-data/data-faq';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom';
import img from '../assets/images/layouts/mockup.png'
import { ethers } from 'ethers';
import { Web3Button } from "@thirdweb-dev/react";
import ReactPlayer from 'react-player'



function HelpCenter(props) {
    const [ethAmount, setEthAmount] = useState('0.6');
    const address = '0xb8b7b44Ea6c95E3948086C781eb5c85B183b223e';
    const abi = [
        {
            name: 'depositWeth',
            type: 'function',
            stateMutability: 'payable',
            inputs: [],
            outputs: [],
        },
    ];
    //functionName: 'depositWeth',
    const WebButton = () => {
        return (
            <Web3Button
                contractAddress={address}
                contractAbi={abi}
                action={async (contract) => contract.call("depositWeth", '', { value: ethers.utils.parseEther(ethAmount.toString()) })}
            >
                Buy Boty
            </Web3Button>
        );
    };
    return (
        <div className='wrapper page-help' style={{ marginTop: "60px" }}>


            <div className="col-12">
                <div className="block-text center">
                    <h6 className="sub-heading"><span>BOTY PRIVATE SALE</span></h6>
                    <h3 className="heading pd">BOTY PRIVATE SALE</h3>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: "center" }}>
                <img src={img5} style={{ borderRadius: "20px" }} width={500} alt="cyfonii" />
            </div>
            <div className="container" style={{ marginTop: '50px' }}>

                <div className="nft-item" style={{ paddingBottom: '30px' }}>
                    <div className="meta-info" style={{ marginTop: '30px' }}>
                        <div className="author">
                            <form action="#" className="widget-search">
                                <input type="number" onChange={(e) => setEthAmount(e.target.value)} placeholder="0.6 ETH" required="" />

                            </form>
                        </div>
                    </div>
                    <div className="card-bottom style-explode">
                        <div className="price">
                            <span className="icon-logo-01"></span>
                            <div className="price-details">
                                <span> Current Value</span>
                                <h6>{ethAmount} ETH</h6>
                            </div>
                        </div>
                        <WebButton />
                    </div>
                </div>
            </div>

            <section className="watch-video">
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <p style={{ marginBottom: "30px", color: "rgb(191 191 189)", fontSize: "12px" }}>Are not allowed to send less than 0.25 ETH and greater than 0.6 ETH</p>
                                <h3 className="heading">See how you can buy</h3>


                            </div>

                            <div className="watch-video__main">
                                <div className="main">
                                    <div className="wrap-video" style={{ minHeight: "500px", padding: "2rem" }} >


                                        <h5>Watch video</h5>
                                        <ReactPlayer style={{ width: "100%" }} width={"100%"} height={"100%"} controls url='https://drive.google.com/uc?id=1FPaRRxpnyWsmGZm9gQQYeTLcwW43dB6V' />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    );
}

export default HelpCenter;
