import React, { useState } from 'react';
import PropTypes from 'prop-types';


import img1 from '../../assets/images/layouts/about-01.png'
import img2 from '../../assets/images/layouts/about-02.jpeg'
import img3 from '../../assets/images/layouts/about-03.jpeg'
import img4 from '../../assets/images/layouts/about-04.jpeg'
import img5 from '../../assets/images/layouts/about-05.png'
import Button from '../button/Button';



function About({ refProp }) {

    const [dataImg] = useState([
        {
            id: 1,
            img: img1,
            class: 'img1'
        },
        {
            id: 2,
            img: img2,
            class: 'img2'
        },
        {
            id: 3,
            img: img3,
            class: 'img3'
        },
        {
            id: 4,
            img: img4,
            class: 'img4'
        },
        {
            id: 5,
            img: img5,
            class: 'img5'
        },
    ])

    const [dataBlock] = useState(
        {
            subheading: 'About Boty',
            heading: 'BOTY  is sent to give the memecoins the place they deserve',
            desc1: 'Boty, the son of R2D2 and C3PO, is sent to Earth, to give memecoins the visibility they deserve. We are going to give the first-ever trip to space to a human! He or she will be in charge of guarding the private key of the tokens reserved for its parents, the driving force behind this mission. We want to unite communities by being that meme that everyone will be talking about for having achieved an epic dream come true.',
            desc2: '"To the moon" is not just an expression for BOTY, and for those who join us, it can become the most fascinating journey they have ever been on in the crypto world. Take a seat, traveler, because this is about to begin.',
            desc3: ' Engines on... Three... two... one...',

        }
    )
    return (
        <section ref={refProp} id='about' className="about">
            <div className="shape"></div>
            <div className="container">
                <div className="row rev">
                    <div className="col-xl-6 col-md-12">
                        <div className="about__right">
                            <div className="images">
                                {
                                    dataImg.map(idx => (
                                        <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text" >
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading">{dataBlock.heading}</h3>
                            <p className="mb-17">{dataBlock.desc1}</p>
                            <p className="mb-26">{dataBlock.desc2}</p>
                            <p className="mb-26">{dataBlock.desc3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
