import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Partner from '../partner/Partner';

Team.propTypes = {
    data: PropTypes.array
};

function Team(props) {

    const { data, refProp } = props;

    const [dataBlock] = useState(
        {
            subheading: 'Team Members',
            heading: 'Our Amazing Team Members',
        }
    )

    return (
        <section ref={refProp} id="team" className="team">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>
                        </div>

                        <Swiper
                            className="team-swiper"
                            spaceBetween={30}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                500: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                991: {
                                    slidesPerView: 4,
                                },
                            }}
                            loop={true}

                        >

                            {
                                data.map(idx => (
                                    <SwiperSlide key={idx.id}>
                                        <div className="swiper-slide">
                                            <div className="team-box">
                                                <div className="image">
                                                    <Link to="/#">
                                                        <img src={idx.img} alt="Cyfonii" />
                                                    </Link>
                                                </div>
                                                <div className="content">
                                                    <Link to="/#" className="h5 name">{idx.name}</Link>
                                                    <p className="postion">
                                                        {idx.position}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }

                        </Swiper>





                    </div>

                </div>
            </div>
        </section>
    );
}

export default Team;
