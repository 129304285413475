import React, { useState } from 'react';



function Speciality(props) {

    const { data, refProp } = props;

    const [dataBlock] = useState(
        {
            subheading: 'Boty Journey',
            heading: 'Check out the missions',
            desc: '50% of the supply will keep blocked – 50% is gonna be used to help Boty to achivied the missions!',

        }
    )
    return (
        <section ref={refProp} className="speciality">
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>
                            <p className="">{dataBlock.desc}</p>
                        </div>
                    </div>
                    {
                        data.map(idx => (
                            <div key={idx.id} className="col-xl-3 col-md-6">
                                <div className="speciality-box" >
                                    <div className="icon">
                                        <img src={idx.img} alt="Cyfonii" style={{ width: 64, fill: 'white' }} />
                                    </div>
                                    <h5 className="title">{idx.title}</h5>
                                    <p>{idx.desc}</p>
                                    <h3 className="number">0{idx.id}</h3>
                                </div>
                            </div>
                        ))
                    }

                    <div id="mission" ></div>
                </div>
            </div>
        </section>

    );
}

export default Speciality;
