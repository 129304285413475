


const dataFaqs = [
    {
        "id": 1,
        "title": "Will BOTY be listed on any exchanges?",
        "text": "We're currently liaising with some major exchanges to make BOTY available for trading.",
        "show": ""
    },
    {
        "id": 2,
        "title": "How can I acquire NFTs and what benefits do they provide?",
        "text": "NFTs can be earned freely through staking. They grant exclusive access to events and our proprietary IA Trading Bot.",
        "show": ""
    },
    {
        "id": 3,
        "title": "How do I stake $BOTY?",
        "text": "Staking functionality for $BOTY will be coming soon. Stay tuned!",
        "show": ""
    },
    {
        "id": 4,
        "title": "What utility do the NFTs offer?",
        "text": "Our NFTs provide entry to unique events and access to our upcoming IA Trading Bot.",
        "show": ""
    },
    {
        "id": 5,
        "title": "What's the objective of Mission 1: Times Square?",
        "text": "Mission 1 involves a BOTY showcase on a large screen right in the heart of New York City!",
        "show": ""
    },
    {
        "id": 6,
        "title": "What's planned for Mission 2: Stratosphere?",
        "text": "We're collaborating with a team of space engineers to take BOTY beyond our atmosphere.",
        "show": ""
    },
    {
        "id": 7,
        "title": "What's the goal of Mission 3: Everest?",
        "text": "In Mission 3, BOTY will be carried up the world's tallest peak by a seasoned mountaineer.",
        "show": ""
    },
    {
        "id": 8,
        "title": "What's in store for Mission 4: Outer Space?",
        "text": "BOTY will take a spacial journey, accompanied by the lucky winner of our NFTs owner giveaway! This will be an official event, supervised by a notary.",

        "show": ""
    },
    {
        "id": 9,
        "title": "Are there more missions to come?",
        "text": "Indeed, there are more missions planned. However, we're keeping them under wraps for now.",
        "show": ""
    }
]

export default dataFaqs;
