import React from 'react';
import dataBox from '../assets/fake-data/data-box';
import dataFaqs from '../assets/fake-data/data-faq';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataTestimonials from '../assets/fake-data/data-testimonials';
import dataProject from '../assets/fake-data/dataProject';
import dataTeam from '../assets/fake-data/dataTeam';
import About from '../components/about/About';
import Banner from '../components/banner/Banner';
import Create from '../components/create/Create';
import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer/Footer';
import Portfolio from '../components/portfolio/Portfolio';
import Project from '../components/project/Project';
import Roadmap from '../components/roadmap/Roadmap';
import Speciality from '../components/speciality/Speciality';
import Team from '../components/team/Team';
import Testimonials from '../components/testimonials/Testimonials';
import { useRef } from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import About3 from '../components/about/About3';
import Token from '../components/token/Token';
import Header from '../components/header/Header';
import Popup from '../components/popup';

function Home01(props) {
    const botyRef = useRef(null)
    const tokenomicsRef = useRef(null)
    const aboutRef = useRef(null)
    const roadmapRef = useRef(null)
    const teamRef = useRef(null)
    const faqRef = useRef(null)
    const communityRef = useRef(null)
    const buyRef = useRef(null)
    return (
        <div className="home-1 wrapper">

            <Header refProps={{ botyRef, buyRef, tokenomicsRef, aboutRef, roadmapRef, teamRef, faqRef, communityRef }} />
            <Banner refProp={aboutRef} />

            <Speciality refProp={botyRef} data={dataBox} />

            <About refProp={aboutRef} />
            <About3 />

            <Token refProp={tokenomicsRef} />

            <Portfolio refProp={buyRef} data={dataPortfolio} />


            <Roadmap refProp={roadmapRef} data={dataRoadMap} />

            <Team refProp={teamRef} data={dataTeam} />


            <Faqs refProp={faqRef} data={dataFaqs} />


            <PageTitle refProp={communityRef} title="Communities" />
            <Popup />
            <Footer />
        </div>


    );
}

export default Home01;
