import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/layouts/banner.jpeg'
import img2 from '../../assets/images/icon/icon-01.png'
import img3 from '../../assets/images/layouts/avt-01.png'
import telegram from '../../assets/images/icon/telegram.png'

function Banner({ refProp }) {
    const missionRef = useRef(null)
    const scrollToMission = () => {
        window.scrollTo({
            top: missionRef.current.offsetTop,
            behavior: 'smooth'
        })
    }

    return (
        <section style={{ paddingLeft: "35px" }} className="banner">
            <div className="shape right"></div>
            <div className="container big">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__left">
                            <div className="block-text">
                                <h2 className="heading">Join <br />
                                    Boty’s{' '}
                                    <span className="s1 arlo_tm_animation_text_word"> adventures!</span>  <br />
                                    <br />
                                    Be
                                    <br />
                                    part of it!</h2>
                                <p className="desc">Have you already seen the plans of the son of R2D2 and C3PO?</p>

                                <button onClick={scrollToMission} className="action-btn"><span>Discover the missions</span></button>
                            </div>
                            {/*

                            <div className="pay">
                                <h6>This is the official address</h6>

                                <div className="list">
                                    <p>COMING SOON</p>
                                    <br />
                                    <p>Follow us in telegram to be aware of the launch</p>
                                    <img
                                        src={telegram}
                                        alt="telegram"
                                        width="20"
                                    />

                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">

                        <div className="banner__right">
                            <div className="image">
                                <img src={img1} alt="cyfonii" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={missionRef}></div>
        </section>
    );
}

export default Banner;
