
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Connect your wallet',
        desc: 'Start your journey by connecting your digital wallet. This is your main tool for managing and trading BOTY tokens. Choose a secure and reliable wallet - one that fits your convenience and comfort.'
    },
    {
        id: 2,
        img: img2,
        title: 'Ensure that you have ETH for gas fees in your wallet',
        desc: 'In the world of cryptocurrency, transactions require fuel. Gas fees are paid in Ethereum (ETH). Make sure you have enough ETH in your wallet to cover these transaction costs, ensuring smooth and quick transactions.'
    },
    {
        id: 3,
        img: img3,
        title: 'You can now buy your BOTY token',
        desc: 'Now, you\'re all set to buy BOTY tokens. These tokens are not just digital assets; they are your gateway to a myriad of benefits and privileges in our community. Acquire them, keep them, and watch your digital portfolio grow.'
    },
    {
        id: 4,
        img: img4,
        title: 'Now let’s take BOTY to beyond the moon',
        desc: 'With your BOTY tokens in your wallet, you\'re ready to join us on an exciting journey. Our goal is not just the moon; it\'s beyond. By holding and trading BOTY tokens, you\'ll be contributing to our voyage to redefine the limits of cryptocurrency.'
    },


]

export default dataPortfolio;
