

const dataRoadMap = [
    {
        "id": 1,
        "time": "PHASE 1",
        "title": "Launch and Listing",
        "desc": "During this phase, we initiate the launch of our BOTY token. Subsequently, we list it on major platforms like CoinGecko and CoinMarketCap. Our pet BOTY embarks on its first real-world journey to Times Square, with the aim of gathering 2,000+ holders.",
        "class": ""
    },
    {
        "id": 2,
        "time": "PHASE 2",
        "title": "Expansion and Diversification",
        "desc": "The second phase brings a new adventure for our pet BOTY as it ascends to the Stratosphere. This phase also marks the launch of our staking platform and the release of our NFTs collection. In addition, our BOTY token evolves into a multichain token.",
        "class": "right"
    },
    {
        "id": 3,
        "time": "PHASE 3",
        "title": "Product and Service Development",
        "desc": "In phase three, our pet BOTY scales the world's tallest peak, Everest. During this daring climb, we will also launch the IA Trading Bot, and the BOTY tokens will get listed on further exchanges.",
        "class": ""
    },
    {
        "id": 4,
        "time": "PHASE 4",
        "title": "Event and Marketplace Launch",
        "desc": "As our pet BOTY concludes its Everest journey, we celebrate by launching the first BOTY event and our NFTs marketplace. We anticipate that BOTY will catch significant media attention during this phase.",
        "class": "right"
    },
    {
        "id": 5,
        "time": "PHASE 5",
        "title": "Outreach and Giveaways",
        "desc": "In Phase 5, our pet BOTY embarks on a spectacular journey to Outer Space. We will commemorate this landmark event with a significant giveaway, featuring a space trip as the grand prize. Our goal is for BOTY to become the first token in space.",
        "class": ""
    },
    {
        "id": 6,
        "time": "PHASE 6",
        "title": "Future Endeavours",
        "desc": "The journey does not end at Phase 5. We have more thrilling plans for our pet BOTY's travels and milestones. We look forward to sharing more as we continue on this exciting journey together.",
        "class": "right"
    }
]
export default dataRoadMap;
