import React, { useState } from 'react';


import Button from '../button/Button';

import img1 from '../../assets/images/layouts/banner-04.png'
import img2 from '../../assets/images/layouts/banner-05.png'

import avt from '../../assets/images/layouts/avt-05.png'






function About3(props) {

    const [dataBlock] = useState(
        {
            subheading: 'Tokenomics',
            heading: 'How the Boty’s Army is distributed',
            desc1: 'One of the premises of Boty is that 50% of the tokens must be sent to their parents R2D2 and C3PO in the spaceship when Boty makes its return journey. The Boty army has then made a distribution the tokens so that the project can continue to move forward and accomplish objectives and missions.',
            desc2: 'Of the remaining 50% of the tokens, 60% will be used to provide liquidity to the market and supply Botys to everyone who chooses to participate in this journey. Next, 15% will go towards the development of the token and the operation of the token itself, while another 15% will go to you, in the form of various prizes and surprises to come.',
            desc3: 'Finally, Boty has a lot of ideas in mind and, together with his invincible army, they intend to make the rules of the game change for memecoins as well. Leaving for this reason 10% for future projects to maintain the ecosystem.',

        }
    )
    const [dataCard] = useState(
        [
            {
                id: 1,
                img: img1,
                title: '3D Digital Artwork',
                price: '2.26 ETH',
                avt: avt,
                name: 'Leslie Alexander',
                tag: '@leslie754'
            },
            {
                id: 2,
                img: img1,
                title: '3D Digital Artwork',
                price: '2.26 ETH',
                avt: avt,
                name: 'Leslie Alexander',
                tag: '@leslie754',
                class: 'sm'
            },
        ]
    )
    return (
        <section className="about s3">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text pd-0">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading">{dataBlock.heading}</h3>
                            <p className="mb-17">{dataBlock.desc1}</p>
                            <p className="mb-17">{dataBlock.desc2}</p>
                            <p className="mb-17">{dataBlock.desc3}</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12" style={{ backgroundColor: '#3B307E', borderRadius: '50px', display: 'grid', alignItems: 'center' }} >
                        <div className="about__right" data-aos="fade-left" data-aos-duration="2000">

                            <img src={img1} alt="Cyfonii" style={{ objectFit: "contain", width: "100%" }} />

                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default About3;
