
import { React, useEffect } from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import routes from './pages';
import Page404 from './pages/404';
import Header from './components/header/Header';
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { Mumbai } from "@thirdweb-dev/chains";
function App() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        <>

            <ThirdwebProvider
                activeChain={"ethereum"}
            >

                <Routes>

                    {
                        routes.map((data, idx) => (
                            <Route key={idx} path={data.path} element={data.component} exact />
                        ))
                    }

                    <Route path='*' element={<Page404 />} />
                </Routes>
            </ThirdwebProvider>
        </>
    );
}

export default App;
