const menus = [
    {
        id: 1,
        name: 'BOTY\'S JOURNEY',
        links: '#',

    },
    {
        id: 2,
        name: 'TOKENOMICS',
        links: '#',

    },
    {
        id: 3,
        name: 'ABOUT',
        links: '#',

    },
    {
        id: 4,
        name: 'ROADMAP',
        links: '#',

    },
    {
        id: 5,
        name: 'TEAM',
        links: '#',

    },
    {
        id: 6,
        name: 'FAQ',
        links: '#',

    },
    {
        id: 7,
        name: 'COMMUNITY',
        links: '#',

    },


]

export default menus;
