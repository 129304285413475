
import React, { useEffect, useState } from 'react';
import img5 from '../assets/images/layouts/about-05.png'

import PageTitle from '../components/pagetitle/PageTitle';
import FaqPage from '../components/faqs/FaqPage';
import dataFaqs from '../assets/fake-data/data-faq';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom';
import img from '../assets/images/layouts/mockup.png'
import { ethers } from 'ethers';
import { Web3Button } from "@thirdweb-dev/react";



function HelpCenter(props) {
    const [ethAmount, setEthAmount] = useState('0.6');
    const address = "0xb8b7b44Ea6c95E3948086C781eb5c85B183b223e";
    const tokenAddress = "0x3fF5181596c7a17713704843aE437bB090b4f410";
    const abi = [
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "token",
                    "type": "address"
                }
            ],
            "name": "withdraw",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },

    ];
    //functionName: 'depositWeth',
    const WebButton = () => {
        return (
            <Web3Button
                contractAddress={address}
                contractAbi={abi}
                action={async (contract) => contract.call("withdraw", ["0x3fF5181596c7a17713704843aE437bB090b4f410"])}
            >
                Claim $BOTY
            </Web3Button>
        );
    };
    return (
        <div className='wrapper page-help' style={{ marginTop: "60px" }}>


            <div className="col-12">
                <div className="block-text center">
                    <h6 className="sub-heading"><span>BOTY PRIVATE SALE</span></h6>
                    <h3 className="heading pd">BOTY WITHDRAW </h3>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: "center" }}>
                <img src={img5} style={{ borderRadius: "20px" }} width={500} alt="cyfonii" />
            </div>
            <div className="container" style={{ marginTop: '50px' }}>

                <div className="nft-item" style={{ paddingBottom: '30px' }}>
                    <div className="meta-info" style={{ marginTop: '30px' }}>
                        <div className="author">
                            <WebButton />
                        </div>
                    </div>
                </div>
            </div>

            <section className="watch-video">
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                {//<p style={{ marginBottom: "30px", color: "rgb(191 191 189)", fontSize: "12px" }}>Are not allowed to send less than 0.25 ETH and greater than 0.6 ETH</p>}
                                }                              <h3 className="heading">See how you can withdraw</h3>


                            </div>

                            <div className="watch-video__main">
                                <div className="main">
                                    <div className="wrap-video">
                                        <a href="https://www.youtube.com/watch?v=i7EMACWuErA" className="popup-youtube">
                                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.853 7.25168C16.2247 8.01369 16.2247 9.9863 14.853 10.7483L2.97129 17.3493C1.63822 18.0899 -7.9231e-07 17.1259 -7.25651e-07 15.601L-1.48576e-07 2.39903C-8.19178e-08 0.874059 1.63822 -0.0898765 2.97129 0.650714L14.853 7.25168Z" fill="#D9D9D9" />
                                                <path d="M14.853 7.25168C16.2247 8.01369 16.2247 9.9863 14.853 10.7483L2.97129 17.3493C1.63822 18.0899 -7.9231e-07 17.1259 -7.25651e-07 15.601L-1.48576e-07 2.39903C-8.19178e-08 0.874059 1.63822 -0.0898765 2.97129 0.650714L14.853 7.25168Z" fill="url(#paint0_linear_787_6121)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_787_6121" x1="43.9319" y1="31.9348" x2="38.9828" y2="-12.545" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.164688" stopColor="#DEC7FF" />
                                                        <stop offset="0.855177" stopColor="#5C27FE" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                        </a>
                                    </div>

                                    <h5>Watch video</h5>

                                    <img src={img} alt="Cyfonii" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    );
}

export default HelpCenter;
