
import img1 from '../images/icon/icon-1.svg'
import img2 from '../images/icon/icon-2.svg'
import img3 from '../images/icon/icon-3.svg'
import img4 from '../images/icon/icon-4.svg'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'TIMES SQUARE',
        desc: 'Boty in the big screen in Times Square, New York'
    },
    {
        id: 2,
        img: img2,
        title: 'STRATOSPHERE',
        desc: 'What an amazing view from the top of the Stratosphere'
    },
    {
        id: 3,
        img: img3,
        title: 'EVEREST',
        desc: 'In the top of the world, in the top of the Everest'
    },
    {
        id: 4,
        img: img4,
        title: 'OUTER SPACE',
        desc: 'Boty will meet his parents again on the Moon!'
    },

]

export default dataBox;
