
import img1 from '../images/layouts/team-11.jpeg'
import img2 from '../images/layouts/team-12.jpeg'
import img3 from '../images/layouts/team-13.jpeg'
import img4 from '../images/layouts/team-14.jpeg'
import img5 from '../images/layouts/team-05.png'
import img6 from '../images/layouts/team-06.png'
import img7 from '../images/layouts/team-07.png'
import img8 from '../images/layouts/team-08.png'

const dataTeam = [

    {
        id: 1,
        img: img1,
        name: 'Boty Master',
        position: ''
    },
    {
        id: 2,
        img: img2,
        name: 'Boty Developer',
        position: ''
    },

    {
        id: 3,
        img: img3,
        name: 'Boty Holder',
        position: ''
    },

    {
        id: 4,
        img: img4,
        name: 'Boty Staker',
        position: ''
    },

]

export default dataTeam;
